.quotation-detail-container {
    display: flex;
    flex-flow: column;
}

.quotation-detail-edit-container {
    padding: 15px 10px 10px 10px;
}

.carriers-scrollable-table {
    .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container {
        height: 100%;

        .ant-table-body {
            height: calc(100% - 112px) !important;

            .ant-table-selection-column {
                padding: 0px 16px;
            }
        }
    }
}

.quotation-detail-table-summary-ammount-row {
    height: 30px;

    td {
        background: #f0f2f5;
        font-weight: bold;
        white-space: nowrap;
    }
}

.quotation-detail-filter-body {
    padding: 16px;
    width: 100%;

    .gray-small-label {
        white-space: nowrap;
    }
}

.carrier-detail-tab-container {
    display: flex;
    flex-flow: column;
    padding: 16px;
}

.quotation-filter-column-container {
    display: flex;
    flex-flow: row;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: space-between;
}

.quotation-input-container {
    display: flex;
    flex-flow: column;
}

.quotation-status-button-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-top: 2px;

    button:not(:last-child) {
        margin-right: 10px;
    }
}

.quotation-detail-table-title-container {
    background: #033a4f;
    color: #849fa9;
    padding: 5px 24px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    align-items: center;
}

.quotation-detail-table-title {
    font-family: RobotoLight;
}

.carrier-detail-tabs {
    height: 100%;

    .ant-tabs-nav {
        margin: 0;
    }

    .ant-tabs-content-holder {
        overflow-y: scroll;
        margin-top: 44px;
    }

    .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
            display: flex;
            justify-content: center;
            width: 25%;
            background: #f4f7fb;
            border: 0px;
            border-radius: 0 !important;

            &.ant-tabs-tab-active {
                background: #2683de;

                .ant-tabs-tab-btn {
                    color: #fff;
                }
            }
        }
    }

    &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
        display: none;
    }
}

.quotation-status-radiobutton-container {
    margin-right: 30px;

    .quotation-status-radiobutton {
        text-transform: uppercase;
        font-family: RobotoMedium;
        height: 26px;
        line-height: 26px;
        font-size: 13px;

        &:hover:before {
            background-color: #fff;
        }

        &:before {
            background-color: #fff;
        }

        &.ant-radio-button-wrapper-checked {
            &.draft {
                background: #0093ba;
                border-color: #0093ba;
            }

            &.reserve {
                background: #8100ce;
                border-color: #8100ce;
            }

            &.order {
                background: #9ecd1f;
                border-color: #9ecd1f;
            }
        }
    }

    .ant-radio-button-wrapper {


        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.nested-quotation-table {
    .ant-table-thead {
        tr {
            height: 20px;
        }
    }

    .ant-table-content {
        th {
            padding: 2px 10px;
        }

        td {
            padding: 2px 10px;
        }
    }
}

.carrier-progress-label {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    font-size: 12px;
    color: #fff;
}

.subcarrier-detail-tab-container {
    display: flex;
    flex-flow: column;
    padding: 16px;
}

.quotation-detail-tab-row {
    display: flex;
    flex-flow: row;
}

.subcarrier-modal {

    .ant-modal-body {
        height: 800px;
        padding: 0px;
    }
}

.subcarry-status-container {
    display: flex;
    color: #fff;
    align-items: center;
    border-radius: 2px;
    justify-content: space-between;
    width: 95px;

    .subcarry-status {
        text-align: center;
        width: 75px;
    }

    .subcarry-status-icon {
        text-align: center;
        width: 20px;
        height: 100%;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        cursor: pointer;
    }

    &.draft {
        background: #0093ba;

        .subcarry-status-icon {
            background: #0183a5;
        }
    }

    &.reserved {
        background: #8100ce;

        .subcarry-status-icon {
            background: #8937cf;
        }
    }
}

.subcarry-quantity-input {
    width: 60px;
}

.subcarrier-footer-buttons-container {
    display: flex;
    justify-content: space-between;
}

.subcarrier-tag-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 10px;

    .ant-tag:last-child {
        margin-right: 0px;
    }
}

.subcarrier-tag {
    width: fit-content;
}

.subcarrier-input-group-container {
    &.ant-input-group {
        display: flex;
    }
}

.subcarrier-dropdown-button {
    &.ant-btn-group {
        margin-left: 8px;
    }
}

.quotation-detail-summary-status-label {
    margin-right: 15px;
}

.input-row-container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 30px;
}

.input-multiple-items-container {
    display: flex;
    width: 100%;
    align-items: flex-end;
    gap: 10px;
}

.input-row-item {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.carrier-detail-summary-container {
    width: 100%;
    display: flex;
    font-family: 'RobotoLight';
    position: absolute;
    font-size: 13px;
    gap: 2px;
    margin-top: 38px;
}

.carrier-detail-summary-item {
    width: 100%;
    color: #a0a0a0;
    text-align: left;
    padding: 5px 20px 5px 40px;
    background: #f4f7fb;
    transition: all 0.3s cubic-bezier(.645,.045,.355,1);

    &.active {
        background: #2683de;
        color: #fff;
    }
}

.carrier-detail-summary-item-price-container {
    display: flex;
    line-height: 1.2;
}

.carrier-detail-summary-item-price {
    width: 100%;
    font-size: 14px;
    font-family: 'RobotoBold';
}

.carrier-detail-summary-currency {
    //font-size: 14px;
}

.carrier-detail-summary-item-title {
    margin-bottom: 3px;
    background: #fff;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -5px;
    padding: 0 20px;
    color: #3e3e3e;
    font-family: 'RobotoBold';
}

.carrier-detail-summary-item-price-title {
    width: 100%;
}

.timeline-progress {
    --progress-background-color: #d7d7d7;
    --progress-fade-color: #1890ff;
    width: 50px;
    margin: 0px 10px;

    .ant-progress-inner {
        background: var(--progress-background-color);

        .ant-progress-bg {
            height: 4px !important;
            background-color: var(--progress-fade-color);
        }
    }

    &.timeline-progress[data-progress="created"] {
        --progress-background-color: rgba(0, 202, 255, 0.3);
        --progress-fade-color: #00caff;
    }

    &.timeline-progress[data-progress="draft"] {
        --progress-background-color: rgba(0, 147, 186, 0.3);
        --progress-fade-color: #0093ba;
    }

    &.timeline-progress[data-progress="signed"] {
        --progress-background-color: rgba(255, 126, 36, 0.3);
        --progress-fade-color: #ff7e24;
    }

    &.timeline-progress[data-progress="running"] {
        --progress-background-color: rgba(158, 205, 31, 0.3);
        --progress-fade-color: #9ecd1f;
    }

    &.timeline-progress[data-progress="finished"] {
        --progress-background-color: rgba(139, 172, 38, 0.3);
        --progress-fade-color: #8bac26;
    }

    &.timeline-progress[data-progress="cancelled"] {
        --progress-background-color: rgba(104, 118, 122, 0.3);
        --progress-fade-color: #68767a;
    }

    &.timeline-progress[data-progress="ordered"] {
        --progress-background-color: rgba(158, 205, 31, 0.3);
        --progress-fade-color: #9ecd1f;
    }

    &.timeline-progress[data-progress="reserved"] {
        --progress-background-color: rgba(151, 66, 224, 0.3);
        --progress-fade-color: #8100ce;
    }

    &.timeline-progress[data-progress="valid"] {
        --progress-background-color: rgba(158, 205, 31, 0.3);
        --progress-fade-color: #9ecd1f;
    }

    &.timeline-progress[data-progress="warning"] {
        --progress-background-color: rgba(255, 111, 0, 0.3);
        --progress-fade-color: #ff6f00;
    }

    &.timeline-progress[data-progress="expiring"] {
        --progress-background-color: rgba(215, 51, 1, 0.3);
        --progress-fade-color: #d73301;
    }

    &.timeline-progress[data-progress="expired"] {
        --progress-background-color: rgba(215, 1, 1, 0.3);
        --progress-fade-color: #d70101;
    }

    &.timeline-progress[data-progress="disabled"] {
        --progress-background-color: rgba(207, 207, 207, 0.3);
        --progress-fade-color: #cfcfcf;
    }

    &.timeline-progress[data-progress="waiting"] {
        --progress-background-color: rgba(24, 144, 255, 0.3);
        --progress-fade-color: rgba(24, 144, 255, 0.3);
    }

    &.timeline-progress[data-progress="started"] {
        --progress-background-color: rgba(24, 144, 255, 0.3);
        --progress-fade-color: #1890ff;
    }
}

.carriers-individual-name {
    color: #1890ff;
}

.carrier-discount-cell {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #f0f0f0;
}

.quotation-tri-status-container {
    display: flex;
    height: 4px;
    width: 100px;
    border-radius: 2px;
    overflow: hidden;
}

.quotation-tri-status-item {
    width: 100%;

    &:not(:last-child) {
        border-right: 2px solid #fff;
    }

    &.draft {
        background: #0093ba;
    }

    &.reserved {
        background: #8100ce;
    }

    &.ordered {
        background: #9ecd1f;
    }

    &.disabled {
        background: #cfcfcf;
    }

    &.quotation-tri-status-item-radius {
        border-radius: 2px;
    }
}

.quoation-approve-button {
    background: #9ecd1f;
    color: #fff;
    border-color: #7eac03;

    &:hover {
        background: #82ac11;
        color: #fff;
        border-color: #7eac03;
    }

    &:focus {
        background: #9ecd1f;
        color: #fff;
        border-color: #7eac03;
    }
}

.dropdown-custom-button-carrier-status {
    --button-background: #9ecd1f;
    --button-background-hover: #82ac11;
    --button-border-color: #551e75;

    > :first-child {
        display: none;
    }

    .ant-dropdown-trigger {
        background: var(--button-background);
        color: #fff;
        border-color: var(--button-border-color);
        text-transform: uppercase;
        font-size: 13px;

        &:hover {
            background: var(--button-background-hover);
            color: #fff;
            border-color: var(--button-border-color);
        }

        &:focus {
            background: var(--button-background-hover);
            color: #fff;
            border-color: var(--button-border-color);
        }
    }

    &[data-button="draft"] {
        --button-background: #007695;
        --button-background-hover: #007695;
        --button-border-color: #007695;
    }

    &[data-button="reserved"] {
        --button-background: #6700a5;
        --button-background-hover: #6700a5;
        --button-border-color: #6700a5;
    }

    &[data-button="ordered"] {
        --button-background: #7ea419;
        --button-background-hover: #7ea419;
        --button-border-color: #7ea419;
    }

    &[data-button="cancelled"] {
        --button-background: #b1b1b1;
        --button-background-hover: #b1b1b1;
        --button-border-color: #b1b1b1;
    }
}

.nested-package-carrier-table {
    .ant-table {
        margin-left: 81px !important;
    }
}

.carrier-package-icon {
    font-size: 14px;
    color: #1890ff;
    margin-right: 10px;
    margin-bottom: 2px;

    &.hidden {
        visibility: hidden;
    }
}

.carrier-package-row {
    td {
        background: #fff7e6;
    }
}

.carrier-cancelled-row {
    td {
        background: rgba(104, 118, 122, 0.1);
    }
}

.subcarrier-table-header {
    display: flex;
    width: 350px;
    align-items: center;

    > :not(:last-child) {
        margin-right: 15px;
    }
}

.subcarrier-table-input-button-label {
    white-space: nowrap;
}

.subcarrier-table-input-button {
    display: flex;
}

.subcarrier-table-header-dropdown-button {
    font-size: 14px;
    height: 26px;
}

.subcarrier-collapse-carrier-detail {
    .ant-collapse-content {
        background: rgba(210, 219, 232, 0.11);
    }
}
.carrier-name-icon {
    min-width: 26px;
}