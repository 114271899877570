.campaign-legend-container {
    display: flex;

    > * {
        margin-right: 10px;
    }
}

.campaign-legend-icons {
    display: flex;
    align-items: center;
}

.campaign-legend-label {
    display: flex;
    align-content: center;
    margin-right: 20px;

    span {
        margin-right: 7px;
    }
}

.drag-bar {
    background: #d9d9d9;
    z-index: 3;
}

.splitter {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-top;
    border-radius: 8px;
    background: #e0e0e1;
}

.campaign-body {
    display: flex;
    flex-flow: row;
}

.quotation-container {
    padding-top: 35px;
}

.quotation-body {
    display: flex;
    flex-flow: row;
    position: relative;
}

.campaigns-scrollable-table {
    .ant-spin-nested-loading, .ant-spin-container, .ant-table, .ant-table-container {
        height: 100%;

        .ant-table-body {
            height: calc(100% - 78px) !important;
        }
    }
}

.splitter-title {
    top: -35px;
    left: 24px;
    font-size: 20px;
    color: #68767a;
    font-family: RobotoLight;
}

.amount-cell-container {
    display: flex;
    justify-content: flex-end;
}

.amount-cell-currency {
    width: 30px;
    text-align: left;
    margin-left: 10px;
}

.amount-cell-text {
    text-align: right
}

.quotation-campaign-icon {
    width: 16px;
    margin-right: 7px;
}

.quotation-buttons-container {
    top: -35px;
    right: 15px;
    display: flex;
    align-items: center;

    *:not(:last-child) {
        margin-right: 20px;
    }
}

.ant-table-tbody {
    .quotation-accepted-row {
        td {
            background: #f0ffec;
            border-bottom: 1px solid #9ecd1f;
            border-top: 1px solid #9ecd1f;
        }

        &:hover > td {
            background: #f00;
        }
    }

    .campaign-row {
        td {
            height: 40px;
        }
    }
}

.campaign-table-ams {
    color: #fff;
    height: 28px;
    width: 28px;
    display: flex;
    border-radius: 28px;
    justify-content: center;
    align-items: center;
}

.new-client-modal {
    z-index: 1051;
}

.quotation-title-container {
    display: flex;
    align-items: center;
    position: absolute;
    top: -33px;
    width: 100%;
    justify-content: space-between;
    padding: 0px 30px 0px 24px;
}

.quotation-tri-status-legend-container {
    display: flex;
    align-items: center;
}

.quotation-tri-status-item-legend {
    width: 30px;
    height: 4px;
    margin-right: 5px;
    border-radius: 2px;

    &.draft {
        background: #0093ba;
    }

    &.reserved {
        background: #8100ce;
    }

    &.ordered {
        background: #9ecd1f;
    }

    &.disabled {
        background: #cfcfcf;
    }
}
