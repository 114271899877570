@role-hostess: #d73301;
@role-client: #4290e0;
@role-regional: #8bac26;
@role-coremember: #2f7637;
@role-backoffice: #e36a00;
@role-projectmanager: darkmagenta;
@role-finance: #4bb59b;

.users-role-container {
    display: flex;

    .user-role {
        color: #fff;
        background: #fff;
        margin-right: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
        white-space: nowrap;
        min-width: 120px;

        &.short {
            min-width: 40px;
        }

        &.hostess {
            background: @role-hostess;
        }

        &.customer {
            background: @role-client;
        }

        &.regional {
            background: @role-regional;
        }

        &.coremember {
            background: @role-coremember;
        }

        &.backoffice {
            background: @role-backoffice;
        }

        &.projectmanager {
            background: @role-projectmanager;
        }

        &.finance {
            background: @role-finance;
        }
    }
}

.user-role-dot {
    &:before {
        content: '⬤ ';
        font-size: 14px;
        line-height: 0;
    }

    &.hostess {
        &:before {
            color: @role-hostess;
        }
    }

    &.customer {
        &:before {
            color: @role-client;
        }
    }

    &.regional {
        &:before {
            color: @role-regional;
        }
    }

    &.coremember {
        &:before {
            color: @role-coremember;
        }
    }

    &.backoffice {
        &:before {
            color: @role-backoffice;
        }
    }

    &.projectmanager {
        &:before {
            color: @role-projectmanager;
        }
    }

    &.finance {
        &:before {
            color: @role-finance;
        }
    }
}


.campaign-status-container {
    display: flex;

    .campaign-status {
        color: #fff;
        background: #fff;
        margin-right: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
        width: 85px;

        &.created {
            background: #00caff;
        }

        &.running {
            background: #9ecd1f;
        }

        &.signed {
            background: #ff7e24;
        }

        &.draft {
            background: #0093ba;
        }

        &.confirmed {
            background: #0093ba;
        }

        &.finished {
            background: #8bac26;
        }

        &.cancelled {
            background: #68767a;
        }

        &.ordered {
            background: #9ecd1f;
        }

        &.reserved {
            background: #8100ce;
        }
    }
}

.carrier-status-container {
    display: flex;
    justify-content: flex-end;

    .quotation-status {
        align-items: center;
        display: flex;
        justify-content: center;
        color: #fff;
        background: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
        width: 85px;

        &.ordered {
            background: #9ecd1f;
        }

        &.draft {
            background: #0093ba;
        }

        &.reserved {
            background: #8100ce;
        }

        &.cancelled {
            background: #cfcfcf;
        }
    }
}

.billed-cell {
    color: #8e9b9f;
    font-size: 18px;

    &.paid {
        color: #8bac26;
    }

    &.unpaid {
        color: #d73301;
    }
}

.campaign-company-container {
    display: flex;

    .campaign-company {
        color: #fff;
        background: #fff;
        margin-right: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
        width: 85px;

        &.empcz {
            background: #00caff;
        }

        &.actcz {
            background: #9ecd1f;
        }
    }
}

.campaign-invoice-container {
    display: flex;

    .campaign-invoice {
        color: #fff;
        background: #fff;
        margin-right: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
        width: 55px;

        &.inv {
            background: #9ecd1f;
        }

        &.pinv {
            background: #fb6e01;
        }

        &.noinv {
            background: #cccccc;
        }
    }
}

.partner-container {
    display: flex;

    .partner-tag {
        color: #fff;
        background: #515151;
        margin-right: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
        width: 100px;

        &.albert {
            background: #8bac26;
        }

        &.electroworld {
            background: #004594;
        }

        &.globus {
            background: #e36a00;
        }

        &.kaufland {
            background: #ed1d24;
        }

        &.makro {
            background: #d99e1f;
        }

        &.penny {
            background: #cd1214;
        }

        &.rossmann {
            background: #d61b3a;
        }

        &.tesco {
            background: #07559f;
        }
    }
}

.carrier-status-container {
    display: flex;

    .carrier-status {
        color: #fff;
        background: #fff;
        margin-right: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
        width: 85px;

        &.created {
            background: #00caff;
        }

        &.reserved {
            background: #9ecd1f;
        }

        &.signed {
            background: #ff7e24;
        }

        &.draft {
            background: #0093ba;
        }

        &.confirmed {
            background: #0093ba;
        }

        &.finished {
            background: #8bac26;
        }

        &.cancelled {
            background: #68767a;
        }
    }
}

.carrier-type-container {
    display: flex;

    .carrier-type {
        color: #fff;
        margin-right: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
        width: 55px;
    }
}

.quotation-list-color-row-container {
    display: flex;

    .quotation-list-color-row {
        color: #fff;
        margin-right: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
    }
}