.ant-table-thead > tr > th {
    background: #062b3b;
    color: #849fa9 !important;
    border-radius: 0px !important;
    border: none !important;

    &.ant-table-column-sort {
        background: #0a374a !important;
    }

    &.ant-table-column-has-sorters:hover {
        background: #0a374a !important;
    }
}

.ant-table {
    color: #68767a !important;
    font-size: 12px;

    .ant-table-cell {
        padding: 5px 10px;

        &:last-child {
            padding-right: 20px;
        }
    }

    .ant-table-container {
        border: none !important;

        table {
            border: none !important;
        }
    }
}

.table-no-padding {
    &.ant-table-cell {
        padding: 0px;
    }
}

.ant-pagination {
    padding-right: 24px;
}

.scrollable-table {
    --header-footer: 205px;
    --table-content: 83px; //default table header (33px) & table summary row (50px)
    --page-content: 0px;

    .ant-spin-nested-loading > .ant-spin-container {
        max-height: calc(100vh - var(--header-footer));

        .ant-table {
            .ant-table-body {
                max-height: calc(100vh - (var(--header-footer) + var(--table-content) + var(--page-content))) !important;
            }
        }

        .ant-table[data-table="carriers-table"] {
            --page-content: 164px;
            --table-content: 113px; //plus extra summary row
        }

        .ant-table[data-table="reservation-table"] {
            --table-content: 113px; //plus extra summary row
        }
    }
}

.custom-pagination {
    .ant-pagination {
        position: absolute;
        bottom: 5px;
        right: 80px;
        z-index: 2;
        margin: 4px 0;
    }
}

.table-nowrap {
    .ant-table-tbody {
        tr {
            td {
                white-space: nowrap;
            }
        }
    }

    .ant-table-thead > tr > th {
        white-space: nowrap;
    }
}

.pagination-row {
    height: 50px;
}

.table-height-40 {
    .ant-table-tbody {
        tr {
            height: 40px;
        }
    }
}

.pno-cell {
    font-weight: bold;
    white-space: nowrap;
}

.name-error {
    display: flex;
    align-items: center;
}

.error-cell {
    font-size: 16px;
    margin-right: 10px;
    line-height: 0.9;

    &.red {
        color: #f4001e;
    }

    &.yellow {
        color: #d99e1f;
    }

    &.hidden {
        visibility: hidden;
    }
}

.ammount-align {
    text-align: right !important;
}

.center-align {
    text-align: center !important;
}

.right-align {
    text-align: right !important;
}

.table-buttons-right-align {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.no-right-padding {
    padding-right: 0px !important;
}

.buttons-container {
    display: flex;
    align-items: center;

    > * {
        &:not(:last-child) {
            margin-right: 15px;
        }
    }
}

.nested-table {
    margin-top: 11px;
    margin-bottom: 11px;

    thead, th {
        background: #eaf3fc !important;
    }

    tbody, td {
        background: #f7faff;
    }
}

.ant-table-expanded-row > td {
    background: #eaf3fc !important;
}

.ant-table-cell-scrollbar {
    box-shadow: none;
}

// Summary
.summary-cell {
    position: absolute !important;
    z-index: 10;
    display: flex;
    align-items: center;
    height: 50px;

    &::after {
        box-shadow: none !important;
    }
}

.summary-container {
    display: flex;
}

.summary-legend {
    display: flex;
    align-items: center;
}

.summary-legend-name {
    font-weight: bold;
}

.summary-legend-label {
    margin-right: 20px;
    padding-left: 2px;
    white-space: nowrap;
}

.pagination-changer {
    position: fixed;
    right: 10px;
    width: 70px;
}

.row-selection-cursor {
    td {
        cursor: pointer;
    }
}
