.ant-modal-header {
    background: #1890ff;
    border-bottom: 0px;
}

.ant-modal-close-icon {
    color: #fff;
}

.ant-modal-title {
    color: #fff;
}
