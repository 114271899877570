.a-no-style {
    text-decoration: none;
    color: inherit;
    line-height: 0;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
}

.ant-input-number {
    width: 100%;
}

.default-progress {
    .ant-progress-inner {
        border-radius: 0px;

        .ant-progress-bg {
            height: 3px !important;
        }
    }
}

.filter-buttons {
    position: absolute;
    right: 30px;
    top: 115px;
    display: flex;
    align-items: center;
    height: 30px;

    > * {
        &:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.filter-icon {
    height: 14px;
    margin-top: -5px;

    &:hover {
        cursor: pointer;
    }
}

.quotation-filter-icon {
    height: 14px;

    &:hover {
        cursor: pointer;
    }
}

.custom-button {
    text-transform: uppercase;
    font-family: RobotoMedium;
    padding: 2px 10px;
    border-radius: 4px !important;
    font-size: 13px;
    height: auto;

    &.dark-blue {
        background: #00caff;
        color: #fff;
        border-color: #00caff;

        &:hover {
            background: #0093ba;
            border-color: #0093ba;
        }
    }
}

.icon {
    font-size: 16px;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
        color: #1890ff;
        transition: 0.5s;
    }

    &.white {
        color: #fff;
    }

    &.red-hover {
        &:hover {
            color: #f00;
        }
    }
}

.common-icon {
    height: 16px;
    font-size: 16px;
}

.dropdown-custom-button-icon {
    height: 18px;
    font-size: 18px;
    color: #4a4a4a;
    margin-right: 10px;
    margin-bottom: 2px;
}

.screenshot-image {
    width: 100%;
}

.bold-text {
    font-weight: bold;
}

.dropdown-custom-button-icon {
    height: 14px;
    font-size: 14px;
    color: #4a4a4a;
    margin-right: 20px;
    margin-bottom: 2px;
}

.dropdown-custom-button {

    .ant-btn:first-child {
        margin-right: 0px;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        padding: 2px 10px;
        height: auto;
        text-transform: uppercase;
        font-weight: 400;
    }

    .ant-dropdown-trigger {
        height: auto;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }

    &.dropdown-primary-button {

        .ant-btn:first-child {
            font-family: RobotoMedium;
            font-size: 13px;
            color: #fff;
            background: #1890ff;
            border: none;

            &:hover {
                background: #40a9ff;
            }
        }

        .ant-dropdown-trigger {
            color: #fff;
            background: #1e74c3;
            border: none;
        }
    }
}

.dropdown-menu-container {

    .ant-dropdown-menu-item {
        padding: 7px 20px;
    }

    .ant-dropdown-menu-title-content {
        flex: auto;
        align-items: center;
        display: flex;
    }
}

.small-progress {
    width: 50px;
    margin: 0px 10px;

    .ant-progress-inner {

        .ant-progress-bg {
            height: 4px !important;
        }
    }
}

.cell-flex-right-container {
    display: flex;
    justify-content: flex-end;
}