.progress-status-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 85px;
}

.subcarrier-progress-status {
    --progress-background-color: #c4c4c4;
    --progress-fade-color: #838383;
    height: 18px;
    font-size: 10px;

    .ant-progress-inner {
        height: 18px;
        border-radius: 4px;
        background: var(--progress-background-color);

        .ant-progress-bg {
            height: 18px !important;
            border-radius: 0px;
            background: var(--progress-fade-color);
        }
    }

    &[data-progress="availability-good"] {
        --progress-background-color: #c3e468;
        --progress-fade-color: #9ecd1f;
    }

    &[data-progress="availability-poor"] {
        --progress-background-color: #ffa058;
        --progress-fade-color: #ff6f00;
    }

    &[data-progress="availability-low"] {
        --progress-background-color: #e97f5e;
        --progress-fade-color: #d73301;
    }

    &[data-progress="availability-none"] {
        --progress-background-color: #d70101;
        --progress-fade-color: #d70101;
    }
}

.carrier-progress-label {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    font-size: 12px;
    color: #fff;
}