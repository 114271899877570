@font-face {
  font-family: 'RobotoRegular';
  src: local('RobotoRegular'), url(../resources/fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: 'RobotoBold';
  src: local('RobotoBold'), url(../resources/fonts/Roboto-Bold.ttf);
}
@font-face {
  font-family: 'RobotoMedium';
  src: local('RobotoMedium'), url(../resources/fonts/Roboto-Medium.ttf);
}
@font-face {
  font-family: 'RobotoLight';
  src: local('RobotoLight'), url(../resources/fonts/Roboto-Light.ttf);
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888888;
}
.card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.card {
  background: #fff;
  padding: 12px 15px;
  box-shadow: 0 1px 3px 0px rgba(8, 100, 133, 0.2);
  position: relative;
}
.card-item {
  display: flex;
  flex-flow: column;
  margin-right: 10px;
  width: 100%;
  justify-content: flex-start;
}
.card-item.space-between {
  justify-content: space-between;
}
.card-item:last-child {
  margin-right: 0px;
}
.card-item.row {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.card-item.vertical-spacing:not(:last-child) {
  margin-bottom: 10px;
}
.card-item.half {
  width: 50%;
}
.card-item.one-quarter {
  width: 25%;
}
.card-item.one-fifth {
  width: 20%;
}
.card-item.two-fifth {
  width: 40%;
}
.card-item.three-quarter {
  width: 75%;
}
.card-item.one-third {
  width: 33.33333333%;
}
.card-item.two-third {
  width: 66.66666667%;
}
.card-item.empty {
  visibility: hidden;
}
.ant-table-thead > tr > th {
  background: #062b3b;
  color: #849fa9 !important;
  border-radius: 0px !important;
  border: none !important;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #0a374a !important;
}
.ant-table-thead > tr > th.ant-table-column-has-sorters:hover {
  background: #0a374a !important;
}
.ant-table {
  color: #68767a !important;
  font-size: 12px;
}
.ant-table .ant-table-cell {
  padding: 5px 10px;
}
.ant-table .ant-table-cell:last-child {
  padding-right: 20px;
}
.ant-table .ant-table-container {
  border: none !important;
}
.ant-table .ant-table-container table {
  border: none !important;
}
.table-no-padding.ant-table-cell {
  padding: 0px;
}
.ant-pagination {
  padding-right: 24px;
}
.scrollable-table {
  --header-footer: 205px;
  --table-content: 83px;
  --page-content: 0px;
}
.scrollable-table .ant-spin-nested-loading > .ant-spin-container {
  max-height: calc(100vh - var(--header-footer));
}
.scrollable-table .ant-spin-nested-loading > .ant-spin-container .ant-table .ant-table-body {
  max-height: calc(100vh - (var(--header-footer) + var(--table-content) + var(--page-content))) !important;
}
.scrollable-table .ant-spin-nested-loading > .ant-spin-container .ant-table[data-table="carriers-table"] {
  --page-content: 164px;
  --table-content: 113px;
}
.scrollable-table .ant-spin-nested-loading > .ant-spin-container .ant-table[data-table="reservation-table"] {
  --table-content: 113px;
}
.custom-pagination .ant-pagination {
  position: absolute;
  bottom: 5px;
  right: 80px;
  z-index: 2;
  margin: 4px 0;
}
.table-nowrap .ant-table-tbody tr td {
  white-space: nowrap;
}
.table-nowrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
.pagination-row {
  height: 50px;
}
.table-height-40 .ant-table-tbody tr {
  height: 40px;
}
.pno-cell {
  font-weight: bold;
  white-space: nowrap;
}
.name-error {
  display: flex;
  align-items: center;
}
.error-cell {
  font-size: 16px;
  margin-right: 10px;
  line-height: 0.9;
}
.error-cell.red {
  color: #f4001e;
}
.error-cell.yellow {
  color: #d99e1f;
}
.error-cell.hidden {
  visibility: hidden;
}
.ammount-align {
  text-align: right !important;
}
.center-align {
  text-align: center !important;
}
.right-align {
  text-align: right !important;
}
.table-buttons-right-align {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.no-right-padding {
  padding-right: 0px !important;
}
.buttons-container {
  display: flex;
  align-items: center;
}
.buttons-container > *:not(:last-child) {
  margin-right: 15px;
}
.nested-table {
  margin-top: 11px;
  margin-bottom: 11px;
}
.nested-table thead,
.nested-table th {
  background: #eaf3fc !important;
}
.nested-table tbody,
.nested-table td {
  background: #f7faff;
}
.ant-table-expanded-row > td {
  background: #eaf3fc !important;
}
.ant-table-cell-scrollbar {
  box-shadow: none;
}
.summary-cell {
  position: absolute !important;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 50px;
}
.summary-cell::after {
  box-shadow: none !important;
}
.summary-container {
  display: flex;
}
.summary-legend {
  display: flex;
  align-items: center;
}
.summary-legend-name {
  font-weight: bold;
}
.summary-legend-label {
  margin-right: 20px;
  padding-left: 2px;
  white-space: nowrap;
}
.pagination-changer {
  position: fixed;
  right: 10px;
  width: 70px;
}
.row-selection-cursor td {
  cursor: pointer;
}
.content-container {
  padding-left: 200px;
}
.ant-layout-sider-trigger {
  background: none;
}
.ant-layout-sider-trigger span {
  background-image: url('../resources/images/menu_hamburger.png');
  width: 25px;
  height: 16px;
}
.ant-layout-sider-trigger span svg {
  display: none;
}
.ant-layout-sider {
  background: #032734;
  display: flex;
  flex-flow: column-reverse;
}
.ant-layout-sider .ant-layout-sider-trigger {
  width: inherit;
  position: fixed;
  top: 0px;
}
.ant-layout-sider.ant-layout-sider-collapsed .menu-container .ant-menu-item {
  padding: 0px 30px;
  display: flex;
  align-items: center;
}
.ant-layout-sider.ant-layout-sider-collapsed .menu-container .ant-menu-item .anticon {
  line-height: 0;
}
.ant-layout-sider .menu-container {
  width: 200px;
  position: fixed;
  top: 45px;
}
.ant-layout-sider .ant-menu-dark {
  background: #032734;
}
.ant-layout-header {
  height: 50px !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  background: rgba(5, 45, 63, 0.7) !important;
  position: absolute;
  width: 100%;
  font-size: 12px;
  border-bottom: 1px solid #06394c;
  line-height: 0px;
}
.logo-banner {
  height: 155px;
  min-height: 155px;
  background: url('../resources/images/banner.jpg');
  background-repeat: no-repeat;
  background-color: #052d3f;
  padding-left: 24px;
  padding-top: 115px;
}
.header-title {
  font-size: 20px;
  color: #fff;
  font-family: RobotoLight;
}
.site-layout,
.page-content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding-bottom: 40px;
}
.page-body {
  height: calc(100vh - 195px);
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.logout-icon {
  font-size: 18px;
}
.pos-logo {
  margin-left: 24px;
  margin-right: 20px;
  height: 20px;
}
.user-profile {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;
  color: #869da6;
  font-family: RobotoRegular;
}
.user-name {
  margin-right: 30px;
}
.header-company-dropdown {
  margin-right: 30px;
}
.header-company-dropdown-body {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-company-dropdown-body:hover {
  color: #40a9ff;
}
.header-company-dropdown-title {
  margin-right: 5px;
  cursor: inherit;
}
.header-company-dropdown-item {
  cursor: inherit;
}
.footer {
  height: 40px;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  min-width: inherit;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  background: #f6f8f9;
  border-top: 1px solid #ededed;
  font-size: 12px;
}
.footer .footer-important-text {
  font-weight: bold;
}
.footer .footer-important-text:after {
  content: ' ';
}
.footer-copyright-text {
  display: flex;
  color: rgba(0, 0, 0, 0.6);
}
.alg-logo {
  height: 16px;
  margin-left: 5px;
  margin-right: 5px;
}
.ant-layout-sider + .ant-layout .footer {
  margin-left: 200px;
  transition: all 0.2s;
}
.ant-layout-sider-collapsed + .ant-layout .footer {
  margin-left: 80px;
  transition: all 0.2s;
}
.a-no-style {
  text-decoration: none;
  color: inherit;
  line-height: 0;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}
.ant-input-number {
  width: 100%;
}
.default-progress .ant-progress-inner {
  border-radius: 0px;
}
.default-progress .ant-progress-inner .ant-progress-bg {
  height: 3px !important;
}
.filter-buttons {
  position: absolute;
  right: 30px;
  top: 115px;
  display: flex;
  align-items: center;
  height: 30px;
}
.filter-buttons > *:not(:first-child) {
  margin-left: 20px;
}
.filter-icon {
  height: 14px;
  margin-top: -5px;
}
.filter-icon:hover {
  cursor: pointer;
}
.quotation-filter-icon {
  height: 14px;
}
.quotation-filter-icon:hover {
  cursor: pointer;
}
.custom-button {
  text-transform: uppercase;
  font-family: RobotoMedium;
  padding: 2px 10px;
  border-radius: 4px !important;
  font-size: 13px;
  height: auto;
}
.custom-button.dark-blue {
  background: #00caff;
  color: #fff;
  border-color: #00caff;
}
.custom-button.dark-blue:hover {
  background: #0093ba;
  border-color: #0093ba;
}
.icon {
  font-size: 16px;
  cursor: pointer;
  transition: 0.5s;
}
.icon:hover {
  color: #1890ff;
  transition: 0.5s;
}
.icon.white {
  color: #fff;
}
.icon.red-hover:hover {
  color: #f00;
}
.common-icon {
  height: 16px;
  font-size: 16px;
}
.dropdown-custom-button-icon {
  height: 18px;
  font-size: 18px;
  color: #4a4a4a;
  margin-right: 10px;
  margin-bottom: 2px;
}
.screenshot-image {
  width: 100%;
}
.bold-text {
  font-weight: bold;
}
.dropdown-custom-button-icon {
  height: 14px;
  font-size: 14px;
  color: #4a4a4a;
  margin-right: 20px;
  margin-bottom: 2px;
}
.dropdown-custom-button .ant-btn:first-child {
  margin-right: 0px;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  padding: 2px 10px;
  height: auto;
  text-transform: uppercase;
  font-weight: 400;
}
.dropdown-custom-button .ant-dropdown-trigger {
  height: auto;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.dropdown-custom-button.dropdown-primary-button .ant-btn:first-child {
  font-family: RobotoMedium;
  font-size: 13px;
  color: #fff;
  background: #1890ff;
  border: none;
}
.dropdown-custom-button.dropdown-primary-button .ant-btn:first-child:hover {
  background: #40a9ff;
}
.dropdown-custom-button.dropdown-primary-button .ant-dropdown-trigger {
  color: #fff;
  background: #1e74c3;
  border: none;
}
.dropdown-menu-container .ant-dropdown-menu-item {
  padding: 7px 20px;
}
.dropdown-menu-container .ant-dropdown-menu-title-content {
  flex: auto;
  align-items: center;
  display: flex;
}
.small-progress {
  width: 50px;
  margin: 0px 10px;
}
.small-progress .ant-progress-inner .ant-progress-bg {
  height: 4px !important;
}
.cell-flex-right-container {
  display: flex;
  justify-content: flex-end;
}
.users-role-container {
  display: flex;
}
.users-role-container .user-role {
  color: #fff;
  background: #fff;
  margin-right: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
  white-space: nowrap;
  min-width: 120px;
}
.users-role-container .user-role.short {
  min-width: 40px;
}
.users-role-container .user-role.hostess {
  background: #d73301;
}
.users-role-container .user-role.customer {
  background: #4290e0;
}
.users-role-container .user-role.regional {
  background: #8bac26;
}
.users-role-container .user-role.coremember {
  background: #2f7637;
}
.users-role-container .user-role.backoffice {
  background: #e36a00;
}
.users-role-container .user-role.projectmanager {
  background: darkmagenta;
}
.users-role-container .user-role.finance {
  background: #4bb59b;
}
.user-role-dot:before {
  content: '⬤ ';
  font-size: 14px;
  line-height: 0;
}
.user-role-dot.hostess:before {
  color: #d73301;
}
.user-role-dot.customer:before {
  color: #4290e0;
}
.user-role-dot.regional:before {
  color: #8bac26;
}
.user-role-dot.coremember:before {
  color: #2f7637;
}
.user-role-dot.backoffice:before {
  color: #e36a00;
}
.user-role-dot.projectmanager:before {
  color: darkmagenta;
}
.user-role-dot.finance:before {
  color: #4bb59b;
}
.campaign-status-container {
  display: flex;
}
.campaign-status-container .campaign-status {
  color: #fff;
  background: #fff;
  margin-right: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
  width: 85px;
}
.campaign-status-container .campaign-status.created {
  background: #00caff;
}
.campaign-status-container .campaign-status.running {
  background: #9ecd1f;
}
.campaign-status-container .campaign-status.signed {
  background: #ff7e24;
}
.campaign-status-container .campaign-status.draft {
  background: #0093ba;
}
.campaign-status-container .campaign-status.confirmed {
  background: #0093ba;
}
.campaign-status-container .campaign-status.finished {
  background: #8bac26;
}
.campaign-status-container .campaign-status.cancelled {
  background: #68767a;
}
.campaign-status-container .campaign-status.ordered {
  background: #9ecd1f;
}
.campaign-status-container .campaign-status.reserved {
  background: #8100ce;
}
.carrier-status-container {
  display: flex;
  justify-content: flex-end;
}
.carrier-status-container .quotation-status {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  background: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
  width: 85px;
}
.carrier-status-container .quotation-status.ordered {
  background: #9ecd1f;
}
.carrier-status-container .quotation-status.draft {
  background: #0093ba;
}
.carrier-status-container .quotation-status.reserved {
  background: #8100ce;
}
.carrier-status-container .quotation-status.cancelled {
  background: #cfcfcf;
}
.billed-cell {
  color: #8e9b9f;
  font-size: 18px;
}
.billed-cell.paid {
  color: #8bac26;
}
.billed-cell.unpaid {
  color: #d73301;
}
.campaign-company-container {
  display: flex;
}
.campaign-company-container .campaign-company {
  color: #fff;
  background: #fff;
  margin-right: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
  width: 85px;
}
.campaign-company-container .campaign-company.empcz {
  background: #00caff;
}
.campaign-company-container .campaign-company.actcz {
  background: #9ecd1f;
}
.campaign-invoice-container {
  display: flex;
}
.campaign-invoice-container .campaign-invoice {
  color: #fff;
  background: #fff;
  margin-right: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
  width: 55px;
}
.campaign-invoice-container .campaign-invoice.inv {
  background: #9ecd1f;
}
.campaign-invoice-container .campaign-invoice.pinv {
  background: #fb6e01;
}
.campaign-invoice-container .campaign-invoice.noinv {
  background: #cccccc;
}
.partner-container {
  display: flex;
}
.partner-container .partner-tag {
  color: #fff;
  background: #515151;
  margin-right: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
  width: 100px;
}
.partner-container .partner-tag.albert {
  background: #8bac26;
}
.partner-container .partner-tag.electroworld {
  background: #004594;
}
.partner-container .partner-tag.globus {
  background: #e36a00;
}
.partner-container .partner-tag.kaufland {
  background: #ed1d24;
}
.partner-container .partner-tag.makro {
  background: #d99e1f;
}
.partner-container .partner-tag.penny {
  background: #cd1214;
}
.partner-container .partner-tag.rossmann {
  background: #d61b3a;
}
.partner-container .partner-tag.tesco {
  background: #07559f;
}
.carrier-status-container {
  display: flex;
}
.carrier-status-container .carrier-status {
  color: #fff;
  background: #fff;
  margin-right: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
  width: 85px;
}
.carrier-status-container .carrier-status.created {
  background: #00caff;
}
.carrier-status-container .carrier-status.reserved {
  background: #9ecd1f;
}
.carrier-status-container .carrier-status.signed {
  background: #ff7e24;
}
.carrier-status-container .carrier-status.draft {
  background: #0093ba;
}
.carrier-status-container .carrier-status.confirmed {
  background: #0093ba;
}
.carrier-status-container .carrier-status.finished {
  background: #8bac26;
}
.carrier-status-container .carrier-status.cancelled {
  background: #68767a;
}
.carrier-type-container {
  display: flex;
}
.carrier-type-container .carrier-type {
  color: #fff;
  margin-right: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
  width: 55px;
}
.quotation-list-color-row-container {
  display: flex;
}
.quotation-list-color-row-container .quotation-list-color-row {
  color: #fff;
  margin-right: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
}
.ant-drawer-header {
  border-bottom: none;
  padding: 20px 40px;
  background: #f4f7fb;
}
.ant-drawer-header .ant-drawer-header-title {
  flex: 1;
  flex-flow: row-reverse;
  align-items: baseline;
}
.ant-drawer-header .ant-drawer-title {
  color: #063b4e;
  font-size: 28px;
  letter-spacing: -1px;
}
.ant-drawer-header .ant-drawer-title::after {
  font-size: 12px;
  color: #aaafb1;
  white-space: pre-line;
  display: block;
  line-height: 0.7;
  letter-spacing: 0px;
}
.ant-drawer-body {
  padding: 20px 40px;
  background: #f4f7fb;
}
.drawer-button-container {
  display: flex;
  justify-content: flex-start;
  padding: 20px 0px;
}
.drawer-button-container button:not(:last-child) {
  margin-right: 10px;
}
.st0 {
  fill: #FFFFFF;
}
.error-content {
  background: #5A657A;
  display: flex;
  justify-content: center;
}
.error-description-container {
  text-align: center;
  margin-top: 40px;
}
.error-title {
  color: #d7d7d7;
  font-size: 28px;
}
.error-description {
  color: #d7d7d7;
  font-size: 18px;
  text-align: center;
  width: 400px;
  font-family: Roboto;
}
.error-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}
svg {
  display: block;
  margin: 0 auto;
  overflow: visible !important;
}
svg #robot_1_ {
  animation-name: verticalAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
svg #light_1_ {
  animation-name: blinkLight;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: steps(2);
}
svg #leftEye_1_,
svg #rightEye_1_ {
  animation-name: blinkEye;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
}
svg #leftArm_1_ {
  animation-name: moveLeftArm;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50% 10%;
  transition: all;
}
svg #rightArm_1_ {
  animation-name: moveRightArm;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50% 10%;
}
svg #shadow_1_ {
  animation-name: shadowAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50%;
}
@keyframes wave {
  0% {
    transform: rotate(120deg);
  }
  100% {
    transform: rotate(170deg);
  }
}
@keyframes moveLeftArm {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(4deg);
  }
}
@keyframes moveRightArm {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes shadowAnimation {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.15);
    opacity: 0.6;
  }
}
@keyframes verticalAnimation {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
@keyframes blinkLight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blinkEye {
  0% {
    transform: scaleY(0);
  }
  4% {
    transform: scaleY(1);
  }
}
.ant-breadcrumb {
  font-size: 12px;
}
.ant-breadcrumb a {
  color: #869da6 !important;
  font-family: RobotoRegular;
}
.ant-breadcrumb-separator {
  color: #869da6 !important;
}
.ant-breadcrumb > span:last-child {
  color: #1ed94f !important;
  font-family: RobotoRegular;
}
.ant-breadcrumb {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.ant-breadcrumb .ant-breadcrumb-separator {
  display: flex;
  color: #869da6 !important;
}
.ant-breadcrumb .breadcrumb-item {
  line-height: 12px;
}
.ant-breadcrumb > span {
  display: flex;
  line-height: 0;
}
.ant-breadcrumb > span:first-child .ant-breadcrumb-separator {
  margin-left: 0px;
}
.ant-breadcrumb > span:first-child .breadcrumb-item {
  display: none;
}
.ant-breadcrumb > span:last-child {
  color: #1ed94f !important;
  font-family: RobotoRegular;
}
.ant-modal-header {
  background: #1890ff;
  border-bottom: 0px;
}
.ant-modal-close-icon {
  color: #fff;
}
.ant-modal-title {
  color: #fff;
}
.home-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.home-page-item-container {
  max-width: 1000px;
  padding: 20px 20px 20px 0px;
  display: flex;
  flex-flow: wrap;
  height: fit-content;
}
.home-item {
  width: 300px;
  height: 200px;
  display: flex;
  flex-flow: column;
  padding-bottom: 0px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.home-item .home-item-title {
  display: flex;
  height: 70%;
  flex-flow: column;
  text-align: center;
  margin-bottom: 5px;
  font-size: 18px;
  justify-content: center;
}
.home-item .home-item-title .home-item-badge sup {
  right: 110px;
}
.home-item .home-item-title .anticon {
  margin-bottom: 5px;
  font-size: 36px;
  color: #68767a;
}
.home-item .home-item-description {
  height: 80px;
  overflow: hidden;
  color: #aaafb1;
  font-size: 13px;
  line-height: 20px;
  display: none;
}
.home-item .home-item-footer {
  height: 30%;
  border-top: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login-content {
  height: 100vh;
  background-image: url('../resources/images/login-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container {
  width: 350px;
  background: #fff;
  display: flex;
  flex-flow: column;
  border-radius: 15px;
  background-image: url('../resources/images/login-modal-image.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  padding-top: 230px;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.2);
}
.login-container .login-pos-logo {
  height: 35px;
}
.login-container .login-title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #aaafb1;
}
.login-container .form-login-container {
  padding: 30px;
}
.login-footer {
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0);
  border-top: 0px;
}
.login-footer .footer-copyright-text {
  color: #fff;
}
.campaign-legend-container {
  display: flex;
}
.campaign-legend-container > * {
  margin-right: 10px;
}
.campaign-legend-icons {
  display: flex;
  align-items: center;
}
.campaign-legend-label {
  display: flex;
  align-content: center;
  margin-right: 20px;
}
.campaign-legend-label span {
  margin-right: 7px;
}
.drag-bar {
  background: #d9d9d9;
  z-index: 3;
}
.splitter {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-top;
  border-radius: 8px;
  background: #e0e0e1;
}
.campaign-body {
  display: flex;
  flex-flow: row;
}
.quotation-container {
  padding-top: 35px;
}
.quotation-body {
  display: flex;
  flex-flow: row;
  position: relative;
}
.campaigns-scrollable-table .ant-spin-nested-loading,
.campaigns-scrollable-table .ant-spin-container,
.campaigns-scrollable-table .ant-table,
.campaigns-scrollable-table .ant-table-container {
  height: 100%;
}
.campaigns-scrollable-table .ant-spin-nested-loading .ant-table-body,
.campaigns-scrollable-table .ant-spin-container .ant-table-body,
.campaigns-scrollable-table .ant-table .ant-table-body,
.campaigns-scrollable-table .ant-table-container .ant-table-body {
  height: calc(100% - 78px) !important;
}
.splitter-title {
  top: -35px;
  left: 24px;
  font-size: 20px;
  color: #68767a;
  font-family: RobotoLight;
}
.amount-cell-container {
  display: flex;
  justify-content: flex-end;
}
.amount-cell-currency {
  width: 30px;
  text-align: left;
  margin-left: 10px;
}
.amount-cell-text {
  text-align: right;
}
.quotation-campaign-icon {
  width: 16px;
  margin-right: 7px;
}
.quotation-buttons-container {
  top: -35px;
  right: 15px;
  display: flex;
  align-items: center;
}
.quotation-buttons-container *:not(:last-child) {
  margin-right: 20px;
}
.ant-table-tbody .quotation-accepted-row td {
  background: #f0ffec;
  border-bottom: 1px solid #9ecd1f;
  border-top: 1px solid #9ecd1f;
}
.ant-table-tbody .quotation-accepted-row:hover > td {
  background: #f00;
}
.ant-table-tbody .campaign-row td {
  height: 40px;
}
.campaign-table-ams {
  color: #fff;
  height: 28px;
  width: 28px;
  display: flex;
  border-radius: 28px;
  justify-content: center;
  align-items: center;
}
.new-client-modal {
  z-index: 1051;
}
.quotation-title-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: -33px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 30px 0px 24px;
}
.quotation-tri-status-legend-container {
  display: flex;
  align-items: center;
}
.quotation-tri-status-item-legend {
  width: 30px;
  height: 4px;
  margin-right: 5px;
  border-radius: 2px;
}
.quotation-tri-status-item-legend.draft {
  background: #0093ba;
}
.quotation-tri-status-item-legend.reserved {
  background: #8100ce;
}
.quotation-tri-status-item-legend.ordered {
  background: #9ecd1f;
}
.quotation-tri-status-item-legend.disabled {
  background: #cfcfcf;
}
.quotation-detail-container {
  display: flex;
  flex-flow: column;
}
.quotation-detail-edit-container {
  padding: 15px 10px 10px 10px;
}
.carriers-scrollable-table .ant-spin-nested-loading,
.carriers-scrollable-table .ant-spin-container,
.carriers-scrollable-table .ant-table,
.carriers-scrollable-table .ant-table-container {
  height: 100%;
}
.carriers-scrollable-table .ant-spin-nested-loading .ant-table-body,
.carriers-scrollable-table .ant-spin-container .ant-table-body,
.carriers-scrollable-table .ant-table .ant-table-body,
.carriers-scrollable-table .ant-table-container .ant-table-body {
  height: calc(100% - 112px) !important;
}
.carriers-scrollable-table .ant-spin-nested-loading .ant-table-body .ant-table-selection-column,
.carriers-scrollable-table .ant-spin-container .ant-table-body .ant-table-selection-column,
.carriers-scrollable-table .ant-table .ant-table-body .ant-table-selection-column,
.carriers-scrollable-table .ant-table-container .ant-table-body .ant-table-selection-column {
  padding: 0px 16px;
}
.quotation-detail-table-summary-ammount-row {
  height: 30px;
}
.quotation-detail-table-summary-ammount-row td {
  background: #f0f2f5;
  font-weight: bold;
  white-space: nowrap;
}
.quotation-detail-filter-body {
  padding: 16px;
  width: 100%;
}
.quotation-detail-filter-body .gray-small-label {
  white-space: nowrap;
}
.carrier-detail-tab-container {
  display: flex;
  flex-flow: column;
  padding: 16px;
}
.quotation-filter-column-container {
  display: flex;
  flex-flow: row;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: space-between;
}
.quotation-input-container {
  display: flex;
  flex-flow: column;
}
.quotation-status-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 2px;
}
.quotation-status-button-container button:not(:last-child) {
  margin-right: 10px;
}
.quotation-detail-table-title-container {
  background: #033a4f;
  color: #849fa9;
  padding: 5px 24px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
}
.quotation-detail-table-title {
  font-family: RobotoLight;
}
.carrier-detail-tabs {
  height: 100%;
}
.carrier-detail-tabs .ant-tabs-nav {
  margin: 0;
}
.carrier-detail-tabs .ant-tabs-content-holder {
  overflow-y: scroll;
  margin-top: 44px;
}
.carrier-detail-tabs .ant-tabs-nav-list {
  width: 100%;
}
.carrier-detail-tabs .ant-tabs-nav-list .ant-tabs-tab {
  display: flex;
  justify-content: center;
  width: 25%;
  background: #f4f7fb;
  border: 0px;
  border-radius: 0 !important;
}
.carrier-detail-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background: #2683de;
}
.carrier-detail-tabs .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.carrier-detail-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}
.quotation-status-radiobutton-container {
  margin-right: 30px;
}
.quotation-status-radiobutton-container .quotation-status-radiobutton {
  text-transform: uppercase;
  font-family: RobotoMedium;
  height: 26px;
  line-height: 26px;
  font-size: 13px;
}
.quotation-status-radiobutton-container .quotation-status-radiobutton:hover:before {
  background-color: #fff;
}
.quotation-status-radiobutton-container .quotation-status-radiobutton:before {
  background-color: #fff;
}
.quotation-status-radiobutton-container .quotation-status-radiobutton.ant-radio-button-wrapper-checked.draft {
  background: #0093ba;
  border-color: #0093ba;
}
.quotation-status-radiobutton-container .quotation-status-radiobutton.ant-radio-button-wrapper-checked.reserve {
  background: #8100ce;
  border-color: #8100ce;
}
.quotation-status-radiobutton-container .quotation-status-radiobutton.ant-radio-button-wrapper-checked.order {
  background: #9ecd1f;
  border-color: #9ecd1f;
}
.quotation-status-radiobutton-container .ant-radio-button-wrapper:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.quotation-status-radiobutton-container .ant-radio-button-wrapper:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.nested-quotation-table .ant-table-thead tr {
  height: 20px;
}
.nested-quotation-table .ant-table-content th {
  padding: 2px 10px;
}
.nested-quotation-table .ant-table-content td {
  padding: 2px 10px;
}
.carrier-progress-label {
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 12px;
  color: #fff;
}
.subcarrier-detail-tab-container {
  display: flex;
  flex-flow: column;
  padding: 16px;
}
.quotation-detail-tab-row {
  display: flex;
  flex-flow: row;
}
.subcarrier-modal .ant-modal-body {
  height: 800px;
  padding: 0px;
}
.subcarry-status-container {
  display: flex;
  color: #fff;
  align-items: center;
  border-radius: 2px;
  justify-content: space-between;
  width: 95px;
}
.subcarry-status-container .subcarry-status {
  text-align: center;
  width: 75px;
}
.subcarry-status-container .subcarry-status-icon {
  text-align: center;
  width: 20px;
  height: 100%;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}
.subcarry-status-container.draft {
  background: #0093ba;
}
.subcarry-status-container.draft .subcarry-status-icon {
  background: #0183a5;
}
.subcarry-status-container.reserved {
  background: #8100ce;
}
.subcarry-status-container.reserved .subcarry-status-icon {
  background: #8937cf;
}
.subcarry-quantity-input {
  width: 60px;
}
.subcarrier-footer-buttons-container {
  display: flex;
  justify-content: space-between;
}
.subcarrier-tag-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
}
.subcarrier-tag-container .ant-tag:last-child {
  margin-right: 0px;
}
.subcarrier-tag {
  width: fit-content;
}
.subcarrier-input-group-container.ant-input-group {
  display: flex;
}
.subcarrier-dropdown-button.ant-btn-group {
  margin-left: 8px;
}
.quotation-detail-summary-status-label {
  margin-right: 15px;
}
.input-row-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 30px;
}
.input-multiple-items-container {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 10px;
}
.input-row-item {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.carrier-detail-summary-container {
  width: 100%;
  display: flex;
  font-family: 'RobotoLight';
  position: absolute;
  font-size: 13px;
  gap: 2px;
  margin-top: 38px;
}
.carrier-detail-summary-item {
  width: 100%;
  color: #a0a0a0;
  text-align: left;
  padding: 5px 20px 5px 40px;
  background: #f4f7fb;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.carrier-detail-summary-item.active {
  background: #2683de;
  color: #fff;
}
.carrier-detail-summary-item-price-container {
  display: flex;
  line-height: 1.2;
}
.carrier-detail-summary-item-price {
  width: 100%;
  font-size: 14px;
  font-family: 'RobotoBold';
}
.carrier-detail-summary-item-title {
  margin-bottom: 3px;
  background: #fff;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -5px;
  padding: 0 20px;
  color: #3e3e3e;
  font-family: 'RobotoBold';
}
.carrier-detail-summary-item-price-title {
  width: 100%;
}
.timeline-progress {
  --progress-background-color: #d7d7d7;
  --progress-fade-color: #1890ff;
  width: 50px;
  margin: 0px 10px;
}
.timeline-progress .ant-progress-inner {
  background: var(--progress-background-color);
}
.timeline-progress .ant-progress-inner .ant-progress-bg {
  height: 4px !important;
  background-color: var(--progress-fade-color);
}
.timeline-progress.timeline-progress[data-progress="created"] {
  --progress-background-color: rgba(0, 202, 255, 0.3);
  --progress-fade-color: #00caff;
}
.timeline-progress.timeline-progress[data-progress="draft"] {
  --progress-background-color: rgba(0, 147, 186, 0.3);
  --progress-fade-color: #0093ba;
}
.timeline-progress.timeline-progress[data-progress="signed"] {
  --progress-background-color: rgba(255, 126, 36, 0.3);
  --progress-fade-color: #ff7e24;
}
.timeline-progress.timeline-progress[data-progress="running"] {
  --progress-background-color: rgba(158, 205, 31, 0.3);
  --progress-fade-color: #9ecd1f;
}
.timeline-progress.timeline-progress[data-progress="finished"] {
  --progress-background-color: rgba(139, 172, 38, 0.3);
  --progress-fade-color: #8bac26;
}
.timeline-progress.timeline-progress[data-progress="cancelled"] {
  --progress-background-color: rgba(104, 118, 122, 0.3);
  --progress-fade-color: #68767a;
}
.timeline-progress.timeline-progress[data-progress="ordered"] {
  --progress-background-color: rgba(158, 205, 31, 0.3);
  --progress-fade-color: #9ecd1f;
}
.timeline-progress.timeline-progress[data-progress="reserved"] {
  --progress-background-color: rgba(151, 66, 224, 0.3);
  --progress-fade-color: #8100ce;
}
.timeline-progress.timeline-progress[data-progress="valid"] {
  --progress-background-color: rgba(158, 205, 31, 0.3);
  --progress-fade-color: #9ecd1f;
}
.timeline-progress.timeline-progress[data-progress="warning"] {
  --progress-background-color: rgba(255, 111, 0, 0.3);
  --progress-fade-color: #ff6f00;
}
.timeline-progress.timeline-progress[data-progress="expiring"] {
  --progress-background-color: rgba(215, 51, 1, 0.3);
  --progress-fade-color: #d73301;
}
.timeline-progress.timeline-progress[data-progress="expired"] {
  --progress-background-color: rgba(215, 1, 1, 0.3);
  --progress-fade-color: #d70101;
}
.timeline-progress.timeline-progress[data-progress="disabled"] {
  --progress-background-color: rgba(207, 207, 207, 0.3);
  --progress-fade-color: #cfcfcf;
}
.timeline-progress.timeline-progress[data-progress="waiting"] {
  --progress-background-color: rgba(24, 144, 255, 0.3);
  --progress-fade-color: rgba(24, 144, 255, 0.3);
}
.timeline-progress.timeline-progress[data-progress="started"] {
  --progress-background-color: rgba(24, 144, 255, 0.3);
  --progress-fade-color: #1890ff;
}
.carriers-individual-name {
  color: #1890ff;
}
.carrier-discount-cell {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #f0f0f0;
}
.quotation-tri-status-container {
  display: flex;
  height: 4px;
  width: 100px;
  border-radius: 2px;
  overflow: hidden;
}
.quotation-tri-status-item {
  width: 100%;
}
.quotation-tri-status-item:not(:last-child) {
  border-right: 2px solid #fff;
}
.quotation-tri-status-item.draft {
  background: #0093ba;
}
.quotation-tri-status-item.reserved {
  background: #8100ce;
}
.quotation-tri-status-item.ordered {
  background: #9ecd1f;
}
.quotation-tri-status-item.disabled {
  background: #cfcfcf;
}
.quotation-tri-status-item.quotation-tri-status-item-radius {
  border-radius: 2px;
}
.quoation-approve-button {
  background: #9ecd1f;
  color: #fff;
  border-color: #7eac03;
}
.quoation-approve-button:hover {
  background: #82ac11;
  color: #fff;
  border-color: #7eac03;
}
.quoation-approve-button:focus {
  background: #9ecd1f;
  color: #fff;
  border-color: #7eac03;
}
.dropdown-custom-button-carrier-status {
  --button-background: #9ecd1f;
  --button-background-hover: #82ac11;
  --button-border-color: #551e75;
}
.dropdown-custom-button-carrier-status > :first-child {
  display: none;
}
.dropdown-custom-button-carrier-status .ant-dropdown-trigger {
  background: var(--button-background);
  color: #fff;
  border-color: var(--button-border-color);
  text-transform: uppercase;
  font-size: 13px;
}
.dropdown-custom-button-carrier-status .ant-dropdown-trigger:hover {
  background: var(--button-background-hover);
  color: #fff;
  border-color: var(--button-border-color);
}
.dropdown-custom-button-carrier-status .ant-dropdown-trigger:focus {
  background: var(--button-background-hover);
  color: #fff;
  border-color: var(--button-border-color);
}
.dropdown-custom-button-carrier-status[data-button="draft"] {
  --button-background: #007695;
  --button-background-hover: #007695;
  --button-border-color: #007695;
}
.dropdown-custom-button-carrier-status[data-button="reserved"] {
  --button-background: #6700a5;
  --button-background-hover: #6700a5;
  --button-border-color: #6700a5;
}
.dropdown-custom-button-carrier-status[data-button="ordered"] {
  --button-background: #7ea419;
  --button-background-hover: #7ea419;
  --button-border-color: #7ea419;
}
.dropdown-custom-button-carrier-status[data-button="cancelled"] {
  --button-background: #b1b1b1;
  --button-background-hover: #b1b1b1;
  --button-border-color: #b1b1b1;
}
.nested-package-carrier-table .ant-table {
  margin-left: 81px !important;
}
.carrier-package-icon {
  font-size: 14px;
  color: #1890ff;
  margin-right: 10px;
  margin-bottom: 2px;
}
.carrier-package-icon.hidden {
  visibility: hidden;
}
.carrier-package-row td {
  background: #fff7e6;
}
.carrier-cancelled-row td {
  background: rgba(104, 118, 122, 0.1);
}
.subcarrier-table-header {
  display: flex;
  width: 350px;
  align-items: center;
}
.subcarrier-table-header > :not(:last-child) {
  margin-right: 15px;
}
.subcarrier-table-input-button-label {
  white-space: nowrap;
}
.subcarrier-table-input-button {
  display: flex;
}
.subcarrier-table-header-dropdown-button {
  font-size: 14px;
  height: 26px;
}
.subcarrier-collapse-carrier-detail .ant-collapse-content {
  background: rgba(210, 219, 232, 0.11);
}
.carrier-name-icon {
  min-width: 26px;
}
.reservation-container {
  overflow: scroll;
}
.progress-status-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 85px;
}
.subcarrier-progress-status {
  --progress-background-color: #c4c4c4;
  --progress-fade-color: #838383;
  height: 18px;
  font-size: 10px;
}
.subcarrier-progress-status .ant-progress-inner {
  height: 18px;
  border-radius: 4px;
  background: var(--progress-background-color);
}
.subcarrier-progress-status .ant-progress-inner .ant-progress-bg {
  height: 18px !important;
  border-radius: 0px;
  background: var(--progress-fade-color);
}
.subcarrier-progress-status[data-progress="availability-good"] {
  --progress-background-color: #c3e468;
  --progress-fade-color: #9ecd1f;
}
.subcarrier-progress-status[data-progress="availability-poor"] {
  --progress-background-color: #ffa058;
  --progress-fade-color: #ff6f00;
}
.subcarrier-progress-status[data-progress="availability-low"] {
  --progress-background-color: #e97f5e;
  --progress-fade-color: #d73301;
}
.subcarrier-progress-status[data-progress="availability-none"] {
  --progress-background-color: #d70101;
  --progress-fade-color: #d70101;
}
.carrier-progress-label {
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 12px;
  color: #fff;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: RobotoRegular;
  overflow: hidden;
}
.title-line-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.title-line-label {
  font-size: 14px;
  font-family: RobotoBold;
  color: #68767a;
  white-space: nowrap;
  margin-right: 5px;
}
.title-line-hr {
  height: 1px;
  width: 100%;
  background-color: #dce4e7;
  border: none;
  border-bottom: 1px solid #fff;
  box-sizing: content-box;
}
.gray-small-label {
  font-size: 12px;
  color: #aaafb1;
}
.data-label {
  color: #68767a;
  font-family: RobotoMedium;
}
.data-label.small {
  font-size: 12px;
  font-family: RobotoRegular;
}
.data-label.bold {
  font-weight: bold;
}
.data-label.company-spacing {
  margin-bottom: 18px;
}
.filter-container {
  display: flex;
  flex-flow: column;
}
.filter-container .filter-item {
  display: flex;
  flex-flow: column;
}
.filter-container .filter-item:not(:last-child) {
  margin-bottom: 10px;
}
.financing-role-container {
  display: flex;
}
.financing-role-container .financing-role-dot {
  margin-right: 10px;
}
.financing-role-container .financing-role-dot.hostess {
  color: #e36a00;
}
.financing-role-container .financing-role-dot.coordinator {
  color: #9742e0;
}
