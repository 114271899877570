.ant-breadcrumb {
    font-size: 12px;
}

.ant-breadcrumb a {
    color: #869da6 !important;
    font-family: RobotoRegular;
}

.ant-breadcrumb-separator {
    color: #869da6 !important;
}

.ant-breadcrumb > span:last-child {
    color: #1ed94f !important;
    font-family: RobotoRegular;
}


.ant-breadcrumb {
    font-size: 12px;
    display: flex;
    align-items: center;

    .ant-breadcrumb-separator {
        display: flex;
        color: #869da6 !important;
    }

    .breadcrumb-item {
        line-height: 12px;
    }

    > span {
        display: flex;
        line-height: 0;

        &:first-child {
            .ant-breadcrumb-separator {
                margin-left: 0px;
            }

            .breadcrumb-item {
                display: none;
            }
        }

        &:last-child {
            color: #1ed94f !important;
            font-family: RobotoRegular;
        }
    }
}
