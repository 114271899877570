.content-container {
    padding-left: 200px;
}

// replacing default arrow with hamburger icon
.ant-layout-sider-trigger {
    background: none;

    span {
        background-image: url('../resources/images/menu_hamburger.png');
        width: 25px;
        height: 16px;

        svg {
            display: none;
        }
    }
}

.ant-layout-sider {
    background: @dark-blue;
    display: flex;
    flex-flow: column-reverse;

    .ant-layout-sider-trigger {
        width: inherit;
        position: fixed;
        top: 0px;
    }

    &.ant-layout-sider-collapsed {
        .menu-container {

            .ant-menu-item {
                padding: 0px 30px;
                display: flex;
                align-items: center;

                .anticon {
                    line-height: 0;
                }
            }
        }
    }

    .menu-container {
        width: 200px;
        position: fixed;
        top: 45px;
    }

    .ant-menu-dark {
        background: @dark-blue;
    }
}

.ant-layout-header {
    height: 50px !important;
    padding: 0px !important;
    display: flex;
    align-items: center;
    background: rgba(5, 45, 63, 0.7) !important;
    position: absolute;
    width: 100%;
    font-size: 12px;
    border-bottom: 1px solid #06394c;
    line-height: 0px;
}

.logo-banner {
    height: 155px;
    min-height: 155px;
    background: url('../resources/images/banner.jpg');
    background-repeat: no-repeat;
    background-color: #052d3f;
    padding-left: 24px;
    padding-top: 115px;
}

.header-title {
    font-size: 20px;
    color: #fff;
    font-family: RobotoLight;
}

.site-layout, .page-content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    padding-bottom: 40px;
}

.page-body {
    height: calc(100vh - 195px);
    display: flex;
    flex-flow: column;
    overflow: hidden;
}

.logout-icon {
    font-size: 18px;
}

.pos-logo {
    margin-left: 24px;
    margin-right: 20px;
    height: 20px;
}

.user-profile {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 30px;
    color: #869da6;
    font-family: RobotoRegular;
}

.user-name {
    margin-right: 30px;
}

.header-company-dropdown {
    margin-right: 30px;
}

.header-company-dropdown-body {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: #40a9ff;
    }
}

.header-company-dropdown-title {
    margin-right: 5px;
    cursor: inherit;
}

.header-company-dropdown-item {
    cursor: inherit;
}

// Footer
.footer {
    height: 40px;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    min-width: inherit;
    padding: 10px 24px;
    display: flex;
    justify-content: space-between;
    background: #f6f8f9;
    border-top: 1px solid #ededed;
    font-size: 12px;

    .footer-important-text {
        font-weight: bold;

        &:after {
            content: ' ';
        }
    }
}

.footer-copyright-text {
    display: flex;
    color: rgba(0, 0, 0, 0.60);
}

.alg-logo {
    height: 16px;
    margin-left: 5px;
    margin-right: 5px;
}

.ant-layout-sider + .ant-layout {
    .footer {
        margin-left: 200px;
        transition: all 0.2s;
    }
}

.ant-layout-sider-collapsed + .ant-layout {
    .footer {
        margin-left: 80px;
        transition: all 0.2s;
    }
}
